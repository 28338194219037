const Cookies = require("js-cookie");
const config = require("../config");
var qs = require('qs');
module.exports = {
	list: function() {
		var list = document.getElementById("list-categories");
		if(list){
			new Vue({
				el: '#list-categories',
				data () {
					return {
						categories: [],
						products: [],
						itemCategories: [],
						error: null
					}
				},
				mounted(){
					axios.get(`${config.api}/categories`)
					.then(response => {
						this.categories = response.data
						//console.log(response.data)
					})

					axios.get(`${config.api}/products`)
					.then(response => {
						this.products = response.data
						//console.log(response.data)
					})

					axios.get(`${config.api}/item-categories`)
					.then(response => {
						this.itemCategories = response.data
					})
				}
			})
		}
	},
	listMobile: function() {
		var list = document.getElementById("list-categories-mobile");
		if(list){
			new Vue({
				el: '#list-categories-mobile',
				data () {
					return {
						categories: [],
						error: null
					}
				},
				mounted(){
					axios.get(`${config.api}/categories`)
					.then(response => {
						this.categories = response.data
					})
				}
			})
		}
	},
	listFooter: function() {
		var list = document.getElementById("list-categories-footer");
		if(list){
			new Vue({
				el: '#list-categories-footer',
				data () {
					return {
						categories: [],
						error: null
					}
				},
				mounted(){
					axios.get(`${config.api}/categories`)
					.then(response => {
						this.categories = response.data
					})
				}
			})
		}
	},
	get: function() {
		var list = document.getElementById("category");
		if(list){
			var slugCategory = document.getElementById("idCategory").value;
			new Vue({
				el: '#category',
				data () {
					return {
						category: [],
						categories: [],
						itemCategories: [],
						products: [],
						count: [],
						error: null
					}
				},
				mounted(){
					const query = qs.stringify({
						_where: [{ slug: slugCategory }],
					});
					axios.get(`${config.api}/categories?${query}`)
					.then(responseSlug => {
						axios.get(`${config.api}/categories/${responseSlug.data[0].id}`)
						.then(response => {
							this.category = response.data
						})
						//
						axios.get(`${config.api}/products?category=${responseSlug.data[0].id}`)
						.then(response => {
							this.products = response.data
						})
						//
						axios.get(`${config.api}/products/count?category=${responseSlug.data[0].id}`)
						.then(response => {
							this.count = response.data
						})
					})
					//
					axios.get(`${config.api}/categories`)
					.then(response => {
						this.categories = response.data
					})
					//
					axios.get(`${config.api}/item-categories`)
					.then(response => {
						this.itemCategories = response.data
					})
				}
			})
		}
	}
}