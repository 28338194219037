const Cookies = require("js-cookie");
const config = require("../config");
var qs = require('qs');
module.exports = {
	get: function() {
		var list = document.getElementById("subcategory");
		if(list){
			var slugSubCategory = document.getElementById("idSubCategory").value;
			new Vue({
				el: '#subcategory',
				data () {
					return {
						subcategory: [],
						category: [],
						itemCategories: [],
						categories: [],
						products: [],
						count: [],
						error: null
					}
				},
				mounted(){
					const query = qs.stringify({
						_where: [{ slug: slugSubCategory }],
					});
					axios.get(`${config.api}/subcategories?${query}`)
					.then(responseSlug => {
						axios.get(`${config.api}/subcategories/${responseSlug.data[0].id}`)
						.then(response => {
							this.subcategory = response.data
							axios.get(`${config.api}/categories/${response.data.category.id}`)
							.then(response => {
								this.category = response.data
							})
						})
						//
						axios.get(`${config.api}/products?subcategory=${responseSlug.data[0].id}`)
						.then(response => {
							this.products = response.data
							//console.log(response)
						})
						//
						axios.get(`${config.api}/products/count?subcategory=${responseSlug.data[0].id}`)
						.then(response => {
							this.count = response.data
						})
					})
					//
					axios.get(`${config.api}/categories`)
					.then(response => {
						this.categories = response.data
					})
					//
					axios.get(`${config.api}/item-categories`)
					.then(response => {
						this.itemCategories = response.data
					})
				}
			})
		}
	}
}