import './sass/core.scss'

import $ from 'jquery';

import '@fortawesome/fontawesome-free/js/all.js'

import "bootstrap";

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';
import 'owl.carousel';
import swal from 'sweetalert';

window.axios = require('axios');

const products = require('./js/products');
const categories = require('./js/categories');
const subcategories = require('./js/subcategories');
const itemcategory = require('./js/itemcategory');
const search = require('./js/search');
const form = require('./js/form');
const sliders = require('./js/sliders');
const SocialShareButton = require('./js/SocialShareButton');

$(document).ready(function() {
    //product
    products.list();
    products.get();
    //categories
    categories.list();
    categories.listMobile();
    categories.listFooter();
    categories.get();
    //
    subcategories.get();
    //
    itemcategory.get();
    //
    search.search(); 
    //
    form.signup(); 
    form.contact();
    //
    sliders.list();

    $("#sidebar_fixed ul").on("click", "li.prime", function(){
        $(this).toggleClass("active");
    });

    setTimeout(function() {
        $('body').addClass('no-loader')
    }, 2000)

    if(sessionStorage.getItem('cookie')==null){
        $("#cookiesModal").css("display", "flex"); 
    }
    
    $("#cookieClose").click(function(){
        $("#cookiesModal").css("display", "none");
        sessionStorage.setItem('cookie', true);
    });

    $(".categoria-act button").click(function(){
        $(".categoria-act").toggleClass("active");
        $(".bg-menu").toggleClass("active");
    });

    $(".bg-menu").click(function(){
        if ($(".categoria-act").hasClass("active")) {
            $(".categoria-act").toggleClass("active");
            $(".bg-menu").toggleClass("active");
        }
    });

    $(".main_header").click(function(){
        if ($(".categoria-act").hasClass("active")) {
            $(".categoria-act").toggleClass("active");
            $(".bg-menu").toggleClass("active");
        }
    });

    $('#depoimentos').owlCarousel({
		autoplay:true,
		loop: true,
		margin: 10,
		dots:true,
		nav:false,
		lazyLoad: true,
		autoplayTimeout: 3000,
		responsive: {
			0: {
				items: 1
			}
		}
	});
});

