const Cookies = require("js-cookie");
const config = require("../config");
module.exports = {
	search: function() {
		var search = document.getElementById("search");
		if(search){
            var searchValue = document.getElementById("searchValue").value;
            new Vue({
                el: '#search',
                data () {
                    return {
                        products: [],
                        categories: [],
                        error: null
                    }
                },
                mounted(){
                    //
					axios.get(`${config.api}/categories`)
					.then(response => {
						this.categories = response.data
                        console.log(response.data)
					})
                    //
                    axios.get(`${config.api}/products?name_contains=${searchValue}`)
                    .then(response => {
                        this.products = response.data
                    })
                    //
					axios.get(`${config.api}/item-categories`)
					.then(response => {
						this.itemCategories = response.data
					})
                }
            })
		}
	}
}