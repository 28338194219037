const Cookies = require("js-cookie");
const config = require("../config");
const SocialShareButton = require("../js/SocialShareButton");
module.exports = {
	list: function() {
		var list = document.getElementById("list-products");
		if(list){
            new Vue({
                el: '#list-products',
                data () {
                    return {
                        products: [],
                        error: null
                    }
                },
                mounted(){
                    /* axios.get(`${config.api}/products`)
                    .then(response => {
                        this.products = response.data
                    }) */

                    function getRandomArbitrary(min, max) {
						return Math.random() * (max - min) + min;
					}

					axios.get(`${config.api}/products/count`)
					.then(response => {
						//const { data: totalNumberPosts } = response.data
						const _limit = 20;
						const _start = getRandomArbitrary(0, response.data - _limit);
						const teste = parseInt(_start)

						axios.get(`${config.api}/products?_limit=${_limit}&_start=${teste}`)
						.then(response => {
							this.products = response.data
						})
					})
                }
            })
		}
	},
	get: function() {
		var product = document.getElementById("product");
		if(product){
			var idProduct = document.getElementById("idProduct").value;
            axios.get(`${config.api}/products/${idProduct}`)
            .then(responseProduct => {
                axios.get(`${config.api}/information-fields?product=${idProduct}&type=2`)
                .then(responseInformationFields2 => {
                    axios.get(`${config.api}/information-fields?product=${idProduct}&type=1`)
                    .then(responseInformationFields1 => {
                        axios.get(`${config.api}/products?category=${responseProduct.data.category.id}&id_ne=${idProduct}&_limit=4`)
                        .then(responseRelated => {
                            new Vue({
                                el: '#product',
                                data: {
                                    product: responseProduct.data,
                                    galeries: responseProduct.data.galeries,
                                    information_fields1: responseInformationFields1.data,
                                    information_fields2: responseInformationFields2.data,
                                    suppliers: responseProduct.data.suppliers,
                                    productsRelated: responseRelated.data,
                                    count: 0,
                                    added: true,
                                    error: null
                                },
                                components: {
                                    /* 'vue-recaptcha': VueRecaptcha */
                                },
                                methods: {
                                    /* onCaptchaVerified: function (recaptchaToken) {
                                        $("#btnformConsultation").attr("disabled", false);
                                    },
                                    onCaptchaExpired: function () {
                                        this.$refs.recaptcha.reset();
                                    } */
                                    isObjectEmpty(someObject){
                                        return !(Object.keys(someObject).length)
                                    }
                                },
                                directives: {
                                    inicialed: {
                                        inserted: function (el) {
                                            //
                                            $(".share_buttons").on("click", ".share_item", function(){
                                                var title = $(this).attr("data-title");
                                                var url = $(this).attr("data-url");
                                                SocialShareButton.share(url, title, '700', '400');
                                            });
                                            //action send consultancy
                                            $('form[name="formConsultar"]').submit(function(){
                                                var isEmail = new RegExp(/^[A-Za-z0-9_\-\.]+@[A-Za-z0-9_\-\.]{2,}\.[A-Za-z0-9]{2,}(\.[A-Za-z0-9])?/);
                                                var email = $('#email').val();
                                                var nameSurname = $('#nameSurname').val();
                                                var phone = $('#phone').val();
                                                var deliveryAddress = $('#deliveryAddress').val();
                                                var consultationMessage = $('#consultationMessage').val();
                                                var error = false;

                                                if(nameSurname == '' || email == '' || !isEmail.test(email) || phone == '' || deliveryAddress == '' || consultationMessage == ''){
                                                    error = true;
                                                }

                                                //if all fields have been filled
                                                if(error == true){
                                                    var forms = document.getElementsByClassName('needs-validation');
                                                    var validation = Array.prototype.filter.call(forms, function(form) {
                                                        if (form.checkValidity() === false) {
                                                            event.preventDefault();
                                                            event.stopPropagation();
                                                        } 
                                                        form.classList.add('was-validated'); 
                                                    });
                                                }else{
                                                    var form = $(this);
                                                    url = config.url + 'send-form-consult.php';

                                                    //
                                                    axios.get(`${config.api}/customers?email=${email}`).then(responseCustomer => {
                                                        if(responseCustomer.data.length==0){
                                                            axios.post(`${config.api}/customers`,{
                                                                "name": nameSurname,
                                                                "email": email,
                                                                "phone": phone
                                                            }).then(responseCustomer =>{
                                                                axios.post(`${config.api}/requests`,{
                                                                    "delivery": deliveryAddress,
                                                                    "message": consultationMessage,
                                                                    "customer": responseCustomer.data.id,
                                                                    "product": idProduct
                                                                }).then(responseRequest =>{
                                                                    console.log(responseRequest)
                                                                }).catch(err =>{
                                                                    console.log(err)
                                                                });
                                                            });
                                                        }else{
                                                            for (var i = 0; i < 1; i++) {
                                                                axios.post(`${config.api}/requests`,{
                                                                    "delivery": deliveryAddress,
                                                                    "message": consultationMessage,
                                                                    "customer": responseCustomer.data[0].id,
                                                                    "product": idProduct
                                                                }).then(responseRequest =>{
                                                                    console.log(responseRequest)
                                                                }).catch(err =>{
                                                                    console.log(err)
                                                                });
                                                            }
                                                        }
                                                    });
                                                    //
                                                    $.ajax({
                                                        type: "POST",
                                                        url: url,
                                                        data: form.serialize(),
                                                        async: true, 
                                                        beforeSend: function() { 
                                                            $('#btnformConsultation').addClass("disabled");
                                                            $('#contentButton').addClass("dot-pulse");
                                                        },
                                                        complete: function() { 
                                                            $('#btnformConsultation').removeClass("disabled");
                                                            $('#contentButton').removeClass("dot-pulse");
                                                        },
                                                        success: function(data) {
                                                            $('#response').html(data);        
                                                        }
                                                    });    
                                                }
                                                return false;
                                            });
                                        }
                                    },
                                    carousel: {
                                        inserted: function (el) {
                                            //owl
                                            var owl = $(el);
                                            var owl2 = $(".thumbs");
                                            var owl3 = $(".suppliers");
                                            var changeSlide = 4; // mobile -1, desktop + 1
                                            // Resize and refresh page. slider-two slideBy bug remove
                                            var slide = changeSlide;
                                            if ($(window).width() < 600) {
                                                var slide = changeSlide;
                                                slide--;
                                            } else if ($(window).width() > 999) {
                                                var slide = changeSlide;
                                                slide++;
                                            } else {
                                                var slide = changeSlide;
                                            }
                                            owl.owlCarousel({
                                                nav: true,
                                                items: 1
                                            })
                                            owl3.owlCarousel({
                                                nav: true,
                                                margin: 15,
                                                responsive: {
                                                    0: {
                                                        items: 5
                                                    },
                                                    600: {
                                                        items: 6
                                                    },
                                                    1000: {
                                                        items: 6
                                                    }
                                                }
                                            });
                                            owl2.owlCarousel({
                                                nav: true,
                                                margin: 15,
                                                mouseDrag: false,
                                                touchDrag: true,
                                                responsive: {
                                                    0: {
                                                        items: changeSlide - 1,
                                                        slideBy: changeSlide - 1
                                                    },
                                                    600: {
                                                        items: changeSlide,
                                                        slideBy: changeSlide
                                                    },
                                                    1000: {
                                                        items: changeSlide + 1,
                                                        slideBy: changeSlide + 1
                                                    }
                                                }
                                            });
                                            owl.on("translated.owl.carousel", function (event) {
                                                $(".right").removeClass("nonr");
                                                $(".left").removeClass("nonl");
                                                if ($(".main .owl-next").is(".disabled")) {
                                                    $(".slider .right").addClass("nonr");
                                                }
                                                if ($(".main .owl-prev").is(".disabled")) {
                                                    $(".slider .left").addClass("nonl");
                                                }
                                                $(".slider-two .item").removeClass("active");
                                                var c = $(".slider .owl-item.active").index();
                                                $(".slider-two .item")
                                                    .eq(c)
                                                    .addClass("active");
                                                var d = Math.ceil((c + 1) / slide) - 1;
                                                $(".slider-two .owl-dots .owl-dot")
                                                    .eq(d)
                                                    .trigger("click");
                                            });
                                            $(".right").click(function () {
                                                $(".slider .owl-next").trigger("click");
                                            });
                                            $(".left").click(function () {
                                                $(".slider .owl-prev").trigger("click");
                                            });
                                            $(".slider-two .item").click(function () {
                                                var b = $(".item").index(this);
                                                $(".slider .owl-dots .owl-dot")
                                                    .eq(b)
                                                    .trigger("click");
                                                $(".slider-two .item").removeClass("active");
                                                $(this).addClass("active");
                                            });
                                            owl2.owlCarousel();
                                            owl2.on("translated.owl.carousel", function (event) {
                                                $(".right-t").removeClass("nonr-t");
                                                $(".left-t").removeClass("nonl-t");
                                                if ($(".two .owl-next").is(".disabled")) {
                                                    $(".slider-two .right-t").addClass("nonr-t");
                                                }
                                                if ($(".thumbs .owl-prev").is(".disabled")) {
                                                    $(".slider-two .left-t").addClass("nonl-t");
                                                }
                                            });
                                            owl3.on("translated.owl.carousel", function (event) {
                                                $(".right-t").removeClass("nonr-t");
                                                $(".left-t").removeClass("nonl-t");
                                                if ($(".two .owl-next").is(".disabled")) {
                                                    $(".slider-two .right-t").addClass("nonr-t");
                                                }
                                                if ($(".thumbs .owl-prev").is(".disabled")) {
                                                    $(".slider-two .left-t").addClass("nonl-t");
                                                }
                                            });
                                            $(".right-t").click(function () {
                                                $(".slider-two .owl-next").trigger("click");
                                            });
                                            $(".left-t").click(function () {
                                                $(".slider-two .owl-prev").trigger("click");
                                            });
                                        },
                                    }
                                }
                            })
                        })
                    })
                })
            })
		}
	}
}