const config = require("../config");
module.exports = {
	signup: function() {
        var signup = document.getElementById("signup");
		if(signup){
            $('form[name="singup"]').submit(function(){
                var isEmail = new RegExp(/^[A-Za-z0-9_\-\.]+@[A-Za-z0-9_\-\.]{2,}\.[A-Za-z0-9]{2,}(\.[A-Za-z0-9])?/);
                var email = $('#email').val();
                var nameSurname = $('#nameSurname').val();
                var phone = $('#phone').val();
                var deliveryAddress = $('#deliveryAddress').val();
                var consultationMessage = $('#consultationMessage').val();
                var error = false;

                if(nameSurname == '' || email == '' || !isEmail.test(email) || phone == '' || deliveryAddress == '' || consultationMessage == ''){
                    error = true;
                }

                //if all fields have been filled
                if(error == true){
                    var forms = document.getElementsByClassName('needs-validation');
                    var validation = Array.prototype.filter.call(forms, function(form) {
                        if (form.checkValidity() === false) {
                            event.preventDefault();
                            event.stopPropagation();
                        } 
                        form.classList.add('was-validated'); 
                    });
                }else{
                    var form = $(this);
                    url = config.url + 'send-form-signup.php';
                    
                    //
                    $.ajax({
                        type: "POST",
                        url: url,
                        data: form.serialize(),
                        async: true, 
                        beforeSend: function() { 
                            $('#btnformConsultation').addClass("disabled");
                            $('#contentButton').addClass("dot-pulse");
                        },
                        complete: function() { 
                            $('#btnformConsultation').removeClass("disabled");
                            $('#contentButton').removeClass("dot-pulse");
                        },
                        success: function(data) {
                            $('#response').html(data);
                        }
                    });
                }
                return false;
            });
        }
    },
    contact: function() {
        var contact = document.getElementById("contact");
		if(contact){
            $('form[name="contact"]').submit(function(){
                var isEmail = new RegExp(/^[A-Za-z0-9_\-\.]+@[A-Za-z0-9_\-\.]{2,}\.[A-Za-z0-9]{2,}(\.[A-Za-z0-9])?/);
                var email = $('#email').val();
                var nameSurname = $('#nameSurname').val();
                var phone = $('#phone').val();
                var deliveryAddress = $('#deliveryAddress').val();
                var consultationMessage = $('#consultationMessage').val();
                var error = false;

                if(nameSurname == '' || email == '' || !isEmail.test(email) || phone == '' || deliveryAddress == '' || consultationMessage == ''){
                    error = true;
                }

                //if all fields have been filled
                if(error == true){
                    var forms = document.getElementsByClassName('needs-validation');
                    var validation = Array.prototype.filter.call(forms, function(form) {
                        if (form.checkValidity() === false) {
                            event.preventDefault();
                            event.stopPropagation();
                        } 
                        form.classList.add('was-validated'); 
                    });
                }else{
                    var form = $(this);
                    url = config.url + 'send-form-contact.php';
                    
                    //
                    $.ajax({
                        type: "POST",
                        url: url,
                        data: form.serialize(),
                        async: true, 
                        beforeSend: function() { 
                            $('#btnformConsultation').addClass("disabled");
                            $('#contentButton').addClass("dot-pulse");
                        },
                        complete: function() { 
                            $('#btnformConsultation').removeClass("disabled");
                            $('#contentButton').removeClass("dot-pulse");
                        },
                        success: function(data) {
                            $('#response').html(data);        
                        }
                    });
                }
                return false;
            });
        }
    }
}