const Cookies = require("js-cookie");
const config = require("../config");
var qs = require('qs');
module.exports = {
	list: function() {
		var list = document.getElementById("carousel-home-2");
		if(list){
            axios.get(`${config.api}/sliders`)
            .then(response => {
                new Vue({
                    el: '#carousel-home-2',
                    data: {
                        sliders: response.data,
                        error: null,
                        count: 0,
                        added: true
                    },
                    methods: {

                    },
                    directives: {
                        inicialed: {
                            inserted: function (el) {
                            
                            }
                        },
                        carousel: {
                            inserted: function (el) {
                                $("#carousel-home-2 .owl-carousel").on("initialized.owl.carousel", function() {
                                    setTimeout(function() {
                                        $("#carousel-home-2 .owl-carousel .owl-item.active.center .owl-slide-animated").addClass("is-transitioned");
                                        $("section").show();
                                    }, 200);
                                    });
                                    
                                    const $owlCarousel = $("#carousel-home-2 .owl-carousel").owlCarousel({
                                    loop: true,
                                    margin: 0,
                                    center: true,
                                    nav: false,
                                    dots:true,
                                        responsive: {
                                                0: {
                                                    items: 1,
                                                    dots:false
                                                },
                                                600: {
                                                    items: 1,
                                                    dots:false
                                                },
                                                767: {
                                                    items: 1,
                                                    dots:false
                                                },
                                                1000: {
                                                    items: 1,
                                                    dots:true
                                                },
                                                1400: {
                                                    items: 1,
                                                    dots:true
                                                }
                                            }
                                    });
                                    
                                    $owlCarousel.on("changed.owl.carousel", function(e) {
                                    $(".owl-slide-animated").removeClass("is-transitioned");
                                    const $currentOwlItem = $(".owl-item").eq(e.item.index);
                                    $currentOwlItem.find(".owl-slide-animated").addClass("is-transitioned");
                                    });
                                    
                                    $owlCarousel.on("resize.owl.carousel", function() {
                                    setTimeout(function() {
                                    }, 50);
                                    });
                                    
                            },
                        }
                    }
                })
            })
		}
	},
	listMobile: function() {
		var list = document.getElementById("list-categories-mobile");
		if(list){
			new Vue({
				el: '#list-categories-mobile',
				data () {
					return {
						categories: [],
						error: null
					}
				},
				mounted(){
					axios.get(`${config.api}/categories`)
					.then(response => {
						this.categories = response.data
					})
				}
			})
		}
	},
	listFooter: function() {
		var list = document.getElementById("list-categories-footer");
		if(list){
			new Vue({
				el: '#list-categories-footer',
				data () {
					return {
						categories: [],
						error: null
					}
				},
				mounted(){
					axios.get(`${config.api}/categories`)
					.then(response => {
						this.categories = response.data
					})
				}
			})
		}
	},
	get: function() {
		var list = document.getElementById("category");
		if(list){
			var slugCategory = document.getElementById("idCategory").value;
			new Vue({
				el: '#category',
				data () {
					return {
						category: [],
						categories: [],
						itemCategories: [],
						products: [],
						count: [],
						error: null
					}
				},
				mounted(){
					const query = qs.stringify({
						_where: [{ slug: slugCategory }],
					});
					axios.get(`${config.api}/categories?${query}`)
					.then(responseSlug => {
						axios.get(`${config.api}/categories/${responseSlug.data[0].id}`)
						.then(response => {
							this.category = response.data
						})
						//
						axios.get(`${config.api}/products?category=${responseSlug.data[0].id}`)
						.then(response => {
							this.products = response.data
						})
						//
						axios.get(`${config.api}/products/count?category=${responseSlug.data[0].id}`)
						.then(response => {
							this.count = response.data
						})
					})
					//
					axios.get(`${config.api}/categories`)
					.then(response => {
						this.categories = response.data
					})
					//
					axios.get(`${config.api}/item-categories`)
					.then(response => {
						this.itemCategories = response.data
					})
				}
			})
		}
	}
}