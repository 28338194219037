const Cookies = require("js-cookie");
const config = require("../config");
var qs = require('qs');
module.exports = {
	get: function() {
		var list = document.getElementById("itemcategory");
		if(list){
			var slugSubCategory = document.getElementById("idItemCategory").value;
			new Vue({
				el: '#itemcategory',
				data () {
					return {
						subcategory: [],
						category: [],
                        item: [],
						categories: [],
						itemCategories: [],
						products: [],
						count: [],
						error: null
					}
				},
				mounted(){
					const query = qs.stringify({
						_where: [{ slug: slugSubCategory }],
					});
					axios.get(`${config.api}/item-categories?${query}`)
					.then(responseSlug => {
						axios.get(`${config.api}/item-categories/${responseSlug.data[0].id}`)
						.then(response => {
							this.item = response.data
                            this.subcategory = response.data.subcategory
							axios.get(`${config.api}/categories/${response.data.category.id}`)
							.then(response => {
								this.category = response.data
								console.log(response.data)
							})
						})
						//
						axios.get(`${config.api}/products?item_category=${responseSlug.data[0].id}`)
						.then(response => {
							this.products = response.data
						})
						//
						axios.get(`${config.api}/products/count?item_category=${responseSlug.data[0].id}`)
						.then(response => {
							this.count = response.data
						})
					})
					//
					axios.get(`${config.api}/categories`)
					.then(response => {
						this.categories = response.data
					})
					//
					axios.get(`${config.api}/item-categories`)
					.then(response => {
						this.itemCategories = response.data
					})
				}
			})
		}
	}
}